import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

export default function PayPalWarning () {
  const [safari, setSafari] = React.useState(false)

  React.useEffect(() => {
    const userAgentString = navigator.userAgent
    if (
      userAgentString.includes('Safari') &&
      !userAgentString.includes('Chrome')
    ) {
      setSafari(true)
    }
  }, [])

  return (
    <div>
      <SweetAlert
        danger
        show={safari}
        title='PayPal Problem mit Safari'
        onConfirm={() => setSafari(false)}
      >
        <span>
          Aktuell kommt es beim Bezahlvorgang zu technischen Problemen mit
          verschiedenen Safariversionen. Benutzen sie aktuell bitte einen
          anderen Browser (Firefox, Opera, Edge oder Chrome).
        </span>
      </SweetAlert>
    </div>
  )
}
